import { Injectable } from '@angular/core';
import { DateFormat } from '@shared/enums/common-data/date-format.enum';
import { CorrectionsDateTimeTypesEnum } from '@shared/enums/corrections/corrections-form/timeDeliveryWindow/corrections-time-delivery-window-form.enum';
import { CorrectionsFormCreateRequestResponseModel, CorrectionsTdcFormValueModel } from '@shared/models/corrections/corrections-form/corrections-form.model';
import { TransformCommonDataFieldsService } from '@shared/services/application-utils/transforms/common-data-fields/transform-common-data-fields.service';
import { DateUtils } from '@shared/utils/date/date-utils.service';
import { CorrectionRequest, CorrectionShipment, UpsertCorrectionRequestResp, UpsertCorrectionRequestRqst } from '@xpo-ltl-2.0/sdk-billcorrection';
import { CorrectionDetailCd, TimeDateCritical } from '@xpo-ltl/sdk-common';
import { CorrectionsCreateRequestCommonMapperService } from '../common/corrections-create-request-common-mapper.service';
import { CorrectionsHandler } from '@app-store/corrections/handler/corrections.handler';
import { take } from 'rxjs/operators';
import { CorrectionsTdcSourceCdEnum } from '@shared/enums/corrections/corrections-form/tdc/corrections-tdc-source-cd-form.enum';
import { RequestActionTypeCd } from '@shared/enums/common-data/request-action-type.enum';
import { ManualRateIndMapperService } from '@shared/mappers/common/manual-rate-ind/manual-rate-ind-mapper.service';
import { CorrectionsTimeDeliveryWindowModel } from '@shared/models/corrections/corrections-form/time-delivery-window/corrections-time-delivery-window.model';

@Injectable()
export class CorrectionsCreateRequestTdcMapperService {

  constructor(
    private correctionsCreateRequestCommonMapperService: CorrectionsCreateRequestCommonMapperService,
    private transformCommonDataFieldsService: TransformCommonDataFieldsService,
    private correctionsHandler: CorrectionsHandler,
    private manualRateIndMapperService: ManualRateIndMapperService
  ) { }

  // RESPONSE *********************************************************************************************************************
  getResponse(apiResponse: UpsertCorrectionRequestResp): CorrectionsFormCreateRequestResponseModel {
    return this.correctionsCreateRequestCommonMapperService.getResponse(apiResponse);
  }

  // REQUEST ******************************************************************************************************************
  getRequest(tdcCorrectionsForm: CorrectionsTdcFormValueModel, requestActionTypeCd: RequestActionTypeCd): UpsertCorrectionRequestRqst {
    const request = new UpsertCorrectionRequestRqst();
    request.correctionRequest = this.getCorrectionRequest(tdcCorrectionsForm, requestActionTypeCd);
    request.emailAddresses = this.correctionsCreateRequestCommonMapperService.getEmailAddresses(tdcCorrectionsForm);
    request.shipment = this.getTdcShipment(tdcCorrectionsForm);
    request.correctionDetailCds = [CorrectionDetailCd.TIME_DATE_CRITICAL];
    request.correctionActionTypeCd = this.correctionsCreateRequestCommonMapperService.getActionTypeCd(requestActionTypeCd);
    request.skipNonWorkingDateValidationInd = this.getSkipActionForDate(tdcCorrectionsForm);
    return request;
  }

  getCorrectionRequest(correctionsForm: CorrectionsTdcFormValueModel, requestActionTypeCd: RequestActionTypeCd): CorrectionRequest {
    const response = new CorrectionRequest();
    response.reasonCd = correctionsForm.reason.description;
    response.priorityInd = false;
    response.rateInd = this.manualRateIndMapperService.getRateIndForTdc(correctionsForm.auditorRemarks.manualRateInd);
    response.requesterComment = correctionsForm.requestInfo.requesterComments;
    response.reviewQueue = correctionsForm.requestInfo.correctionQueue;
    response.requestInvoiceInd = true;
    response.correctionRequestInstId = this.correctionsCreateRequestCommonMapperService.getCorrectionRequestInstId(requestActionTypeCd) as number;

    this.correctionsHandler.getCorrectionsDetail$.pipe(
      take(1)
    ).subscribe(
      correctedDetail => {
        if (!correctedDetail) return;
        response.shipmentInstId = correctedDetail.shipmentInstId;
        response.requesterId = correctedDetail.requestInformation.requesterUserId;
      }
    );

    return response;
  }

  private getSkipActionForDate(tdcCorrectionsForm: CorrectionsTdcFormValueModel): boolean {
    const tdcDateError = tdcCorrectionsForm.formIndicators?.tdcDateErrorSkip;
    if (!tdcDateError) return false;

    const newTdcForm = this.getTimeDateCritical(tdcCorrectionsForm.timeDeliveryWindow);
    const oldTdcForm = tdcDateError.value && this.getTimeDateCritical(tdcDateError.value);

    return this.isTdcEqual(newTdcForm, oldTdcForm) && (tdcDateError.skip ?? false);
  }

  private isTdcEqual(newTdcForm: TimeDateCritical, oldTdcForm?: TimeDateCritical): boolean {
    if (
      newTdcForm?.tdcDate1 == oldTdcForm?.tdcDate1 &&
      newTdcForm?.tdcDate2 == oldTdcForm?.tdcDate2 &&
      newTdcForm?.tdcDateTypeCd == oldTdcForm?.tdcDateTypeCd &&
      newTdcForm?.tdcTime1 == oldTdcForm?.tdcTime1 &&
      newTdcForm?.tdcTime2 == oldTdcForm?.tdcTime2 &&
      newTdcForm?.tdcTimeTypeCd == oldTdcForm?.tdcTimeTypeCd
      ) {
        return true;
      }
    else return false;
  }
  private getTdcShipment(tdcCorrectionsForm: CorrectionsTdcFormValueModel): CorrectionShipment {
    const shipment = new CorrectionShipment();
    shipment.timeDateCritical = this.getTimeDateCritical(tdcCorrectionsForm.timeDeliveryWindow);

    return shipment;
  }

  private getTimeDateCritical(timeDeliveryWindow: CorrectionsTimeDeliveryWindowModel): TimeDateCritical {
    if(timeDeliveryWindow.dateSelection === CorrectionsDateTimeTypesEnum.none) return undefined as any;
    const tdcDateTypeCd = timeDeliveryWindow.dateSelection;
    const tdcTimeTypeCd = timeDeliveryWindow.timeSelection === CorrectionsDateTimeTypesEnum.none ? null as any : timeDeliveryWindow.timeSelection;
    const tdcSourceCd = CorrectionsTdcSourceCdEnum.cor;

    let tdcDate1: string = undefined as any;
    let tdcDate2: string = undefined as any;

    let tdcTime1: string = undefined as any;
    let tdcTime2: string = undefined as any;

    const dateFormat = DateFormat.formRawValueDates;

    if (tdcDateTypeCd === CorrectionsDateTimeTypesEnum.on) {
      tdcDate1 = DateUtils.momentToString(timeDeliveryWindow.initialOnDate, dateFormat);
    } else if (tdcDateTypeCd === CorrectionsDateTimeTypesEnum.by) {
      tdcDate1 = DateUtils.momentToString(timeDeliveryWindow.initialByDate, dateFormat);
    } else if (tdcDateTypeCd === CorrectionsDateTimeTypesEnum.range) {
      tdcDate1 = DateUtils.momentToString(timeDeliveryWindow.initialRangeDate, dateFormat);
      tdcDate2 = DateUtils.momentToString(timeDeliveryWindow.endRangeDate, dateFormat);
    }

    if (tdcTimeTypeCd === CorrectionsDateTimeTypesEnum.before) {
      tdcTime1 = this.transformCommonDataFieldsService.getValueFromTimeField(timeDeliveryWindow.initialBeforeTime);
    } else if (tdcTimeTypeCd === CorrectionsDateTimeTypesEnum.after) {
      tdcTime1 = this.transformCommonDataFieldsService.getValueFromTimeField(timeDeliveryWindow.initialAfterTime);
    } else if (tdcTimeTypeCd === CorrectionsDateTimeTypesEnum.range) {
      tdcTime1 = this.transformCommonDataFieldsService.getValueFromTimeField(timeDeliveryWindow.initialRangeTime);
      tdcTime2 = this.transformCommonDataFieldsService.getValueFromTimeField(timeDeliveryWindow.endRangeTime);
    }

    return {
      ...new TimeDateCritical(),
      tdcSourceCd,
      tdcDate1,
      tdcDate2,
      tdcDateTypeCd,
      tdcTime1,
      tdcTime2,
      tdcTimeTypeCd
    };
  }
}
