import { Injectable } from '@angular/core';
import { RecallModel } from '@app-store/recall/actions/recall.actions';
import { RestoreArchivedShipmentResp } from '@xpo-ltl/sdk-shipmentods';
import { DateUtils } from '@shared/utils/date/date-utils.service';

@Injectable({
  providedIn: 'root'
})
export class RecallApiMapperService {

  constructor() { }

  getRecallInfo(recallInfo: RestoreArchivedShipmentResp): RecallModel {
    return {
      ...new RecallModel(),
      proNbr: recallInfo?.proNbr,
      pickupDate: DateUtils.getDateFromTimeStamp(recallInfo?.pickupDate as any)
    };
  }
}
