import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CorrectionsRoutes } from '@shared/routes/corrections-routes.constant';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';

const routes: Routes = [
  {
    path: '',
    canActivate: [XpoAuthenticationGuard],
    children: [
      {
        path: CorrectionsRoutes.main,
        loadChildren: () =>
          import('@corrections/corrections.module').then(
            (module) => module.CorrectionsModule
        ),
      },
      {
        path: CorrectionsRoutes.recall,
        loadChildren: () =>
          import('@recall/recall.module').then(
            (module) => module.RecallModule
        ),
      },
      {
        path: CorrectionsRoutes.deny,
        loadChildren: () =>
          import('@error-pages/deny-access-page/deny-access-page.module').then(
            (module) => module.DenyAccessPageModule
        ),
      },
      {
        path: '**',
        redirectTo: CorrectionsRoutes.main,
        pathMatch: 'full',
      },
    ]
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
