import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ErrorModel } from "@shared/models/shared/error.model";
import { forkJoin, from, of } from "rxjs";
import { catchError, exhaustMap, map, mergeMap, switchMap } from "rxjs/operators";
import { RecallActions, RecallModel } from "../actions/recall.actions";
import { RecallApiService } from "@shared/services/apis/recall/recall-api.service";
import { RecallApiMapperService } from "@shared/mappers/common/recall/recall-api-mapper.service";
import { RestoreArchivedShipmentResp, RestoreArchivedShipmentRqst } from "@xpo-ltl/sdk-shipmentods";

@Injectable()
export class RecallEffects {
  recallPros$ = createEffect(() =>
    this.actions$.pipe(
        ofType(RecallActions.recallPros),
        switchMap(({ proNbrs }) =>
            from(proNbrs).pipe(
                mergeMap(proNbr => {
                    const queryParams = { ...new RestoreArchivedShipmentRqst(), proNbr };
                    return this.recallApiService.recallPro(queryParams).pipe(
                        map((restoredPro: RestoreArchivedShipmentResp) =>
                            RecallActions.recallProsSuccess({ recall: this.recallApiMapperService.getRecallInfo(restoredPro) })
                        ),
                        catchError((errors: ErrorModel) =>
                            of(RecallActions.recallProsFail({ errors: (errors.error || errors), proNbr }))
                        )
                    );
                })
            )
        )
    )
);

    constructor(
        private actions$: Actions,
        private recallApiService: RecallApiService,
        private recallApiMapperService: RecallApiMapperService
    ) { }
}
