import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
/**
 * When it is auto rated the rateInd variable is true
 */
export class ManualRateIndMapperService {
  getManualRateInd(rateInd?: boolean): boolean | undefined {
    return rateInd != null ? !rateInd : undefined;
  }

  getRateInd(manualRateInd: boolean): boolean {
    return !manualRateInd;
  }

  getRateIndForTdc(manualRateInd: boolean): boolean {
    return !manualRateInd;
  }
}
