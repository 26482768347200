import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { RecallEffects } from './effects/recall.effect';
import { RecallHandler } from './handler/recall.handler';
import { RecallReducer } from './reducer/recall.reducer';

import { ErrorCodesService } from '@shared/services/apis-errors/error-codes/error-codes.service';
import { ProNumberModule } from '@shared/services/features/pro-number/pro-number.module';
import { ShipmentOdsApiService } from '@xpo-ltl/sdk-shipmentods';
@NgModule({
  imports: [
    StoreModule.forFeature(
      RecallReducer.recallFeatureKey,
      RecallReducer.reducer
    ),
    EffectsModule.forFeature([RecallEffects]),
    ProNumberModule
  ],
  providers: [
    RecallHandler,
    ShipmentOdsApiService,
    ErrorCodesService,
  ]
})
export class RecallReduxModule {}
