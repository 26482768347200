import { Injectable } from '@angular/core';
import { RequestActionTypeCd } from '@shared/enums/common-data/request-action-type.enum';
import { FormSubmitValidationsModel } from '@shared/models/corrections/corrections-form/form-submit-validations.model';
import { ErrorModel } from '@shared/models/shared/error.model';
import { AccessorialValidatorsService } from '../accessorials-validators.service';
import { AuditorRemarksValidatorsService } from '../auditor-remarks/auditor-remarks-validators.service';
import { ShipperValidatorsService } from '../shipper/shipper-validators.service';
import { NegativeChargesValidatorsService } from '../negative-charges/negative-charges-validators.service';
import { ConsigneeValidatorsService } from '../consignee/consignee-validators.service';
import { BillToValidatorsService } from '../billTo/bill-to-validators.service';

@Injectable()
export class CorrectionsFormSubmitValidatorsService {

  constructor(
    private accessorialValidatorsService: AccessorialValidatorsService,
    private auditorRemarksValidatorsService: AuditorRemarksValidatorsService,
    private consigneeValidatorsService: ConsigneeValidatorsService,
    private billToValidatorsService: BillToValidatorsService,
    private negativeChargesValidatorsService: NegativeChargesValidatorsService,
    private shipperValidatorsService: ShipperValidatorsService,
  ) { }

  submitValidators(params: FormSubmitValidationsModel): ErrorModel[] {
    const acErrors = this.acsValidator(params);
    const auditorCommentsErrors = this.auditorRemarks(params);
    const consigneeErrors = this.consignee(params);
    const billToErrors = this.billTo(params);
    const manualRateIndErrors = this.manualRateInd(params);
    const negativeChargesErrors = this.negativeCharges(params);
    const shipperErrors = this.shipper(params);
    const amexErrors = this.amexValidator(params);
    return [
      ...acErrors,
      ...auditorCommentsErrors,
      ...consigneeErrors,
      ...billToErrors,
      ...manualRateIndErrors,
      ...negativeChargesErrors,
      ...shipperErrors,
      ...amexErrors,
    ];
  }

  submitTdcValidators(params: FormSubmitValidationsModel): ErrorModel[] {
    const manualRateIndErrors = this.manualRateTdcInd(params);
    return [
      ...manualRateIndErrors,
    ];
  }

  private acsValidator(params: FormSubmitValidationsModel): ErrorModel[] {
    const { form, requestActionType } = params;

    const duplicatedAcErrors = requestActionType !== RequestActionTypeCd.notApproved ? this.accessorialValidatorsService.duplicateAcCodeValidator(form) : [];
    return duplicatedAcErrors;
  }

  private auditorRemarks(params: FormSubmitValidationsModel): ErrorModel[] {
    const { form, requestActionType } = params;

    const requiredAuditorComments = requestActionType === RequestActionTypeCd.notApproved
      ? this.auditorRemarksValidatorsService.auditorCommentsRequired(form)
      : [];
    return requiredAuditorComments;
  }

  private consignee(params: FormSubmitValidationsModel): ErrorModel[] {
    const { form, requestActionType } = params;
    if (requestActionType !== RequestActionTypeCd.approved && requestActionType !== RequestActionTypeCd.submit && requestActionType !== RequestActionTypeCd.reSubmit) return [];
    return this.consigneeValidatorsService.requiredFields(form) || [];
  }

  private billTo(params: FormSubmitValidationsModel): ErrorModel[] {
    const { form, requestActionType } = params;
    if (requestActionType !== RequestActionTypeCd.approved && requestActionType !== RequestActionTypeCd.submit && requestActionType !== RequestActionTypeCd.reSubmit) return [];
    return this.billToValidatorsService.requiredFields(form) || [];
  }

  private manualRateInd(params: FormSubmitValidationsModel): ErrorModel[] {
    const { form, requestActionType } = params;

    const requiredManualInd = requestActionType === RequestActionTypeCd.approved || requestActionType === RequestActionTypeCd.reSubmit || requestActionType === RequestActionTypeCd.submit
      ? this.auditorRemarksValidatorsService.manualRateInd(form)
      : [];
    return requiredManualInd;
  }

  private manualRateTdcInd(params: FormSubmitValidationsModel): ErrorModel[] {
    const { form, requestActionType } = params;

    const requiredManualInd = requestActionType === RequestActionTypeCd.approved || requestActionType === RequestActionTypeCd.hold || requestActionType === RequestActionTypeCd.moreInfo
      ? this.auditorRemarksValidatorsService.manualRateTdcInd(form)
      : [];
    return requiredManualInd;
  }

  private negativeCharges(params: FormSubmitValidationsModel): ErrorModel[] {
    return this.negativeChargesValidatorsService.negativeAmount({ form: params.form, requestActionType: params.requestActionType }) || [];
  }

  private shipper(params: FormSubmitValidationsModel): ErrorModel[] {
    const { form, requestActionType } = params;
    if (requestActionType !== RequestActionTypeCd.approved && requestActionType !== RequestActionTypeCd.submit && requestActionType !== RequestActionTypeCd.reSubmit) return [];
    return this.shipperValidatorsService.requiredFields(form) || [];
  }

  private amexValidator(params: FormSubmitValidationsModel): ErrorModel[] {
    const { form, requestActionType } = params;
    if (requestActionType !== RequestActionTypeCd.approved && requestActionType !== RequestActionTypeCd.submit && requestActionType !== RequestActionTypeCd.reSubmit) return [];
    return this.shipperValidatorsService.isMexico(form) || this.consigneeValidatorsService.isMexico(form) ? this.accessorialValidatorsService.isInvalidAmexAcc(form) : [];
  }
}
