import { createFeatureSelector, createReducer, on } from "@ngrx/store";
import { RecallActions } from "../actions/recall.actions";

export const recallFeatureKey = 'recallKey';
const featureSelector = createFeatureSelector<RecallStateModel>(recallFeatureKey);

export interface RecallStateModel {
  recall: any;
};

const initialState: RecallStateModel = {
  recall: undefined,
};

const reducer = createReducer(
  initialState,
);

export const RecallReducer = {
 reducer,
 recallFeatureKey,
 featureSelector,
 initialState
};
