import { Injectable } from "@angular/core";
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from "@ngrx/store";
import { RecallActions } from "../actions/recall.actions";
import { RecallSelectors } from "../selectors/recall.selectors";
import { map } from "rxjs/operators";

@Injectable()
export class RecallHandler {
    // SELECTORS *****************************************************************************************
    recallProsSuccess$ = this.actions$.pipe(
      ofType(RecallActions.recallProsSuccess),
      map((action) => action.recall)
    );
  
    recallProsFail$ = this.actions$.pipe(
      ofType(RecallActions.recallProsFail)
    );

    constructor(private store$: Store, private actions$: Actions) { }

    recallPros(proNbrs: string[]): void {
        this.store$.dispatch(RecallActions.recallPros({proNbrs}));
    }
}
