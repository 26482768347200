import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { RequestActionTypeCd } from '@shared/enums/common-data/request-action-type.enum';
import { CorrectionsFormViewEnum, CorrectionsRequestFormTypeEnum } from '@shared/enums/corrections/corrections-form/corrections-form.enum';
import { CorrectionsFormValueModel, CorrectionsTdcFormModel, CorrectionsTdcFormValueModel } from '@shared/models/corrections/corrections-form/corrections-form.model';
import { PendingRequestStoreModel } from '@shared/models/shared/store/pending-request-store.model';
import { UnratedProService } from '@shared/services/apis-errors/unrated-pro/unrated-pro.service';
import { BillTypeCd, CustomerFunctionCd } from '@xpo-ltl/sdk-common';
import { map } from 'rxjs/operators';
import { CorrectionsActions } from '../actions/corrections.actions';
import { CorrectionsSelectors } from '../selectors/corrections.selectors';
import { CorrectionsBillChargesGetModel } from '@shared/models/corrections/bill-payment/corrections-bill-charges.model';
import { EditMetadataModel } from '@shared/models/shared/metadata.model';
import { ListCorrectionRequestsAndHistoryQuery } from '@xpo-ltl-2.0/sdk-billcorrection';
import { CorrectedRevenueModel } from '@shared/models/corrections/corrections-form/revenues/corrections-revenues.model';

@Injectable()
export class CorrectionsHandler {
  // ACTIONS *****************************************************************************************
  getDetailsSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getDetailsSuccess),
    map((action) => action.correctionsDetail)
  );

  getDetailsFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getDetailsFail),
    map((action) => action.errors)
  );

  getBillChargesSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getBillChargesSuccess),
    map((action) => action.billCharges)
  );

  getBillChargesFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getBillChargesFail),
    map((action) => action.errors)
  );

  getCustomerSicOverrideSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getCustomerSicOverrideSuccess),
    map((action) => action.customerSicOverride)
  );

  getCustomerSicOverrideFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getCustomerSicOverrideFail),
    map((action) => action.errors)
  );

  getAutoRatePreviewSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getAutoRatePreviewSuccess),
    map((action) => action.autoRatesPreview)
  );

  getAutoRatePreviewFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getAutoRatePreviewFail),
    map((action) => action.errors)
  );

  getManualRatePreviewSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getManualRatePreviewSuccess),
    map((action) => action.manualRatesPreview)
  );

  getManualRatePreviewFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getManualRatePreviewFail),
    map((action) => action.errors)
  );

  getListCorrectionsEditMetaDataSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getListCorrectionsEditMetaDataSuccess),
    map((action) => action.correctionsEditMetadata)
  );

  getListCorrectionsEditMetaDataFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getListCorrectionsEditMetaDataFail),
    map((action) => action.errors)
  );

  getListCorrectionsAuditMetaDataSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getListCorrectionsAuditMetaDataSuccess),
    map((action) => action.correctionsAuditMetadata)
  );

  getListCorrectionsAuditMetaDataFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getListCorrectionsAuditMetaDataFail),
    map((action) => action.errors)
  );

  getListCorrectionsReassignMetaDataSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getListCorrectionsReassignMetaDataSuccess),
    map((action) => action.correctionsReassignMetadata)
  );

  getListCorrectionsReassignMetaDataFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getListCorrectionsReassignMetaDataFail),
    map((action) => action.errors)
  );

  getRequestAndHistorySuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getRequestAndHistorySuccess),
    map((action) => action.correctionsRequestAndHistory)
  );

  getRequestAndHistoryFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getRequestAndHistoryFail),
    map((action) => action.errors)
  );

  getRequestAuditInfoSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getRequestAuditInfoSuccess),
    map((action) => action.correctionsRequestAndHistory)
  );

  getRequestAuditInfoFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getRequestAuditInfoFail),
    map((action) => action.errors)
  );

  getAssociatedBillToSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getAssociatedBillToSuccess),
    map((action) => action.associatedBillTo)
  );

  getAssociatedBillToFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getAssociatedBillToFail),
    map((action) => action.errors)
  );

  getRatingInformationSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getRatingInformationSuccess),
    map(action => action.ratingInformationResp)
  );

  getRatingInformationFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getRatingInformationFail),
    map((action) => action.errors)
  );

  getCustomerLocationSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getCustomerLocationSuccess),
    map((action) => action.customerLocation)
  );

  getCustomerLocationFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getCustomerLocationFail),
    map((action) => action.errors)
  );

  getCustomerCreditStatusSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getCustomerCreditStatusSuccess),
    map((action) => action.creditStatus)
  );

  getCustomerCreditStatusFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getCustomerCreditStatusFail),
    map((action) => action.errors)
  );

  getPendingCorrectionDetailsSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getPendingCorrectionDetailsSuccess)
  );

  getPendingCorrectionDetailsFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getPendingCorrectionDetailsFail),
    map((action) => action.errors)
  );

  getRequestQueueSummariesSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getRequestQueueSummariesSuccess),
    map((action) => action.correctionsRequestQueueSummaries)
  );

  getRequestQueueSummariesFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getRequestQueueSummariesFail),
    map((action) => action.errors)
  );

  searchByMadCodeSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.searchByMadCodeSuccess),
    map((action) => action.customer)
  );

  searchByMadCodeFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.searchByMadCodeFail),
    map((action) => action.errors)
  );


  getCorrectedRevenuesSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.getCorrectedRevenuesSuccess),
    map((action) => action.revenuesResp)
  );

  getCorrectedRevenuesFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.getCorrectedRevenuesFail),
    map((action) => action.errors)
  );

  deleteCorrectionsSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.deleteCorrectionsSuccess));

  deleteCorrectionsFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.deleteCorrectionsFail),
    map((action) => action.errors)
  );

  submitRequestSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.submitRequestSuccess),
    map((action) => action.response)
  );

  submitRequestFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.submitRequestFail),
    map((action) => action.errors)
  );

  submitTdcRequestSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.submitTdcRequestSuccess),
    map((action) => action.response)
  );

  submitTdcRequestFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.submitTdcRequestFail),
    map((action) => action.errors)
  );

  submitAuditorRequestSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.submitAuditorRequestSuccess),
    map(action => action.response)
  );

  submitAuditorRequestFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.submitAuditorRequestFail),
    map((action) => action.errors)
  );

  submitTdcAuditorRequestSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.submitTdcAuditorRequestSuccess),
    map(action => action.response)
  );

  submitTdcAuditorRequestFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.submitTdcAuditorRequestFail),
    map((action) => action.errors)
  );

  updateUnlockCorrectionRequestStatusSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.updateUnlockCorrectionRequestStatusSuccess)
  );

  updateUnlockCorrectionRequestStatusFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.updateUnlockCorrectionRequestStatusFail),
    map((action) => action.errors)
  );

  updateQueueForCorrectionRequestsSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.updateQueueForCorrectionRequestsSuccess),
    map((action) => action.response)
  );

  updateQueueForCorrectionRequestsFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.updateQueueForCorrectionRequestsFail),
    map((action) => action.errors)
  );

  resubmitRequestSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.resubmitRequestSuccess),
    map((action) => action.response)
  );
  resubmitRequestFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.resubmitRequestFail),
    map((action) => action.errors)
  );

  resubmitTdcRequestSuccess$ = this.actions$.pipe(
    ofType(CorrectionsActions.resubmitTdcRequestSuccess),
    map((action) => action.response)
  );

  resubmitTdcRequestFail$ = this.actions$.pipe(
    ofType(CorrectionsActions.resubmitTdcRequestFail),
    map((action) => action.errors)
  );

  // SELECTORS *****************************************************************************************
  getProNbr$ = this.store$.pipe(select(CorrectionsSelectors.getProNbr));

  getBillClassCd$ = this.store$.pipe(select(CorrectionsSelectors.getBillClassCd));

  getShipmentInstId$ = this.store$.pipe(select(CorrectionsSelectors.getShipmentInstId));

  getCurrentRequestFormLockedInd$ = this.store$.pipe(select(CorrectionsSelectors.getCurrentRequestFormLockedInd));
  
  getCorrectedCancelChargesInd$ = this.store$.pipe(select(CorrectionsSelectors.getCorrectedCancelChargesInd));

  getRequestFormType$ = this.store$.pipe(select(CorrectionsSelectors.getRequestFormType));

  getRequestFormView$ = this.store$.pipe(select(CorrectionsSelectors.getRequestFormView));

  getTdcOptionRequestFormDisabled$ = this.store$.pipe(select(CorrectionsSelectors.getTdcOptionRequestFormDisabled));

  getCorrectionsBillCharges$ = this.store$.pipe(select(CorrectionsSelectors.getCorrectionsBillCharges));

  getCorrectionsDetail$ = this.store$.pipe(select(CorrectionsSelectors.getCorrectionsDetail));

  getCorrectionsCorrectedDetail$ = this.store$.pipe(select(CorrectionsSelectors.getCorrectionsCorrectedDetail));

  getPickupDate$ = this.store$.pipe(select(CorrectionsSelectors.getPickupDate));

  getCorrectionsHistoryDetail$ = this.store$.pipe(select(CorrectionsSelectors.getCorrectionsHistoryDetail));

  getListCorrectionsEditMetadata$ = this.store$.pipe(select(CorrectionsSelectors.getListCorrectionsEditMetadata));

  getListCorrectionsAuditMetadata$ = this.store$.pipe(select(CorrectionsSelectors.getListCorrectionsAuditMetadata));

  getListCorrectionsReassignMetadata$ = this.store$.pipe(select(CorrectionsSelectors.getListCorrectionsReassignMetadata));

  getAutoRatePreview$ = this.store$.pipe(select(CorrectionsSelectors.getAutoRatePreview));

  getManualRatePreview$ = this.store$.pipe(select(CorrectionsSelectors.getManualRatePreview));
  
  getRequesterId$ = this.store$.pipe(select(CorrectionsSelectors.getRequesterId));
  
  getIsUnrated$ = this.store$.pipe(select(CorrectionsSelectors.getIsUnrated));
  
  getIsLateFeeEnable$ = this.store$.pipe(select(CorrectionsSelectors.getIsLateFeeEnable));

  getListCorrectionsReasonCategories$ = this.store$.pipe(
    select(CorrectionsSelectors.getListCorrectionsEditReasonCategories)
  );

  getListCorrectionsReasonDescriptions$ = this.store$.pipe(
    select(CorrectionsSelectors.getListCorrectionsEditReasonDescriptions)
  );

  getLoadingGetBillCharges$ = this.store$.pipe(select(CorrectionsSelectors.getLoadingGetBillCharges));

  getLoadingCorrectedRevenue$ = this.store$.pipe(select(CorrectionsSelectors.getLoadingCorrectedRevenue));

  getLoadingSubmit$ = this.store$.pipe(select(CorrectionsSelectors.getLoadingSubmit));

  getCurrentRequestForm$ = this.store$.pipe(select(CorrectionsSelectors.getCurrentRequestForm));

  getCurrentRequestFormTdc$ = this.store$.pipe(select(CorrectionsSelectors.getCurrentRequestFormTdc));

  getOriginalRequestForm$ = this.store$.pipe(select(CorrectionsSelectors.getOriginalRequestForm));

  getPricingAgreement$ = this.store$.pipe(select(CorrectionsSelectors.getPricingAgreement));

  updateCorrectionRequestLoading$ = this.store$.pipe(select(CorrectionsSelectors.updateCorrectionRequestLoading));

  constructor(
    private store$: Store,
    private actions$: Actions,
    private unratedProService: UnratedProService
  ) { }

  setProNbr(proNbr: string): void {
    this.store$.dispatch(CorrectionsActions.setProNbr({ proNbr }));
  }

  setIsLateFee(isLateFeeEnable: boolean): void {
    this.store$.dispatch(CorrectionsActions.setIsLateFee({ isLateFeeEnable }));
  }

  setCurrentRequestFormLockedInd(lockedInd: boolean): void {
    this.store$.dispatch(CorrectionsActions.setCurrentRequestFormLockedInd({ lockedInd }));
  }

  setRequestFormType(requestFormType: CorrectionsRequestFormTypeEnum): void {
    this.store$.dispatch(CorrectionsActions.setRequestFormType({ requestFormType }));
  }

  setRequestFormView(requestFormView: CorrectionsFormViewEnum): void {
    this.store$.dispatch(CorrectionsActions.setRequestFormView({ requestFormView }));
  }

  setDisableTdcOptionRequestForm(optionDisabled: boolean): void {
    this.store$.dispatch(CorrectionsActions.setDisableTdcOptionRequestForm({ optionDisabled }));
  }

  clearProNbr(): void {
    this.store$.dispatch(CorrectionsActions.clearProNbr());
  }

  getDetails(proNbr: string): void {
    this.store$.dispatch(CorrectionsActions.getDetails({ proNbr }));
  }

  getBillCharges(billChargesGetVariables: CorrectionsBillChargesGetModel): void {
    this.store$.dispatch(CorrectionsActions.getBillCharges({ billChargesGetVariables }));
  }

  getCustomerSicOverride(customerNbr: number): void {
    this.store$.dispatch(CorrectionsActions.getCustomerSicOverride({ customerNbr }));
  }

  getRatingInformationDetails(shipmentInstId: number): void {
    this.store$.dispatch(CorrectionsActions.getRatingInformation({ shipmentInstId }));
  }

  getAutoRatePreview(proNbr: string, shipInstId: number, correctionsForm: CorrectionsFormValueModel): void {
    this.store$.dispatch(CorrectionsActions.getAutoRatePreview({ proNbr, shipInstId, correctionsForm }));
  }

  getManualRatePreview(proNbr: string, shipInstId: number, correctionsForm: CorrectionsFormValueModel): void {
    this.store$.dispatch(CorrectionsActions.getManualRatePreview({ proNbr, shipInstId, correctionsForm }));
  }

  getCorrectedRevenues(correctedRevenueModel: CorrectedRevenueModel): void {
    this.store$.dispatch(CorrectionsActions.getCorrectedRevenues({ correctedRevenueModel }));
  }

  getListCorrectionsEditMetaData(metadataParams: EditMetadataModel): void {
    this.store$.dispatch(CorrectionsActions.getListCorrectionsEditMetaData({ metadataParams }));
  }

  getListCorrectionsAuditMetaData(loadingOverlayEnabled: boolean = false): void {
    this.store$.dispatch(CorrectionsActions.getListCorrectionsAuditMetaData({ loadingOverlayEnabled }));
  }

  getListCorrectionsReassignMetaData(loadingOverlayEnabled: boolean = false): void {
    this.store$.dispatch(CorrectionsActions.getListCorrectionsReassignMetaData({ loadingOverlayEnabled }));
  }

  getRequestAndHistory(queryParams: ListCorrectionRequestsAndHistoryQuery): void {
    this.store$.dispatch(CorrectionsActions.getRequestAndHistory({ queryParams }));
  }

  getRequestAuditInfo(requestId: number, includeHistoryInd = true): void {
    this.store$.dispatch(CorrectionsActions.getRequestAuditInfo({ requestId, includeHistoryInd }));
  }

  getAssociatedBillTo(customerNumber: number, billCd: BillTypeCd, madCode: string): void {
    this.store$.dispatch(CorrectionsActions.getAssociatedBillTo({ customerNumber, billCd, madCode }));
  }

  getCustomerCreditStatus(customerNumber: number): void {
    this.store$.dispatch(CorrectionsActions.getCustomerCreditStatus({ customerNumber }));
  }

  getCustomerLocation(customerNumber: number): void {
    this.store$.dispatch(CorrectionsActions.getCustomerLocation({ customerNumber }));
  }

  getPendingCorrectionDetails(pendingRequestStore: PendingRequestStoreModel): void {
    this.store$.dispatch(CorrectionsActions.getPendingCorrectionDetails({ pendingRequestStore }));
  }

  getRequestQueueSummaries(loadingOverlayEnabled: boolean = false): void {
    this.store$.dispatch(CorrectionsActions.getRequestQueueSummaries({ loadingOverlayEnabled }));
  }

  searchByMadCode(madCode: string, customerTypes: CustomerFunctionCd[]): void {
    this.store$.dispatch(CorrectionsActions.searchByMadCode({ madCode, customerTypes }));
  }

  deleteCorrections(requestIds: number[]): void {
    this.store$.dispatch(CorrectionsActions.deleteCorrections({ requestIds }));
  }

  setCurrentRequestForm(currentRequestForm: CorrectionsFormValueModel): void {
    this.store$.dispatch(CorrectionsActions.setCurrentRequestForm({ currentRequestForm }));
  }

  setCurrentRequestFormTdc(currentRequestFormTdc: CorrectionsTdcFormModel): void {
    this.store$.dispatch(CorrectionsActions.setCurrentRequestFormTdc({ currentRequestFormTdc }));
  }

  setIsUnrated(isUnratedPro: boolean): void {
    this.store$.dispatch(CorrectionsActions.setIsUnrated({ isUnratedPro }));
  }

  setOriginalRequestForm(originalRequestForm: CorrectionsFormValueModel): void {
    this.store$.dispatch(CorrectionsActions.setOriginalRequestForm({ originalRequestForm }));
  }

  submitRequest(correctionsForm: CorrectionsFormValueModel, originalForm: CorrectionsFormValueModel): void {
    this.store$.dispatch(CorrectionsActions.submitRequest({ correctionsForm, originalForm }));
  }

  submitTdcRequest(tdcCorrectionsForm: CorrectionsTdcFormValueModel): void {
    this.store$.dispatch(CorrectionsActions.submitTdcRequest({ tdcCorrectionsForm }));
  }

  submitAuditorRequest(correctionsForm: CorrectionsFormValueModel, originalForm: CorrectionsFormValueModel, requestActionTypeCd: RequestActionTypeCd): void {
    this.store$.dispatch(CorrectionsActions.submitAuditorRequest({ correctionsForm, originalForm, requestActionTypeCd }));
  }

  submitTdcAuditorRequest(tdcCorrectionsForm: CorrectionsTdcFormValueModel, requestActionTypeCd: RequestActionTypeCd): void {
    this.store$.dispatch(
      CorrectionsActions.submitTdcAuditorRequest({ tdcCorrectionsForm, requestActionTypeCd })
    );
  }

  resubmitRequest(correctionsForm: CorrectionsFormValueModel, originalForm: CorrectionsFormValueModel): void {
    this.store$.dispatch(
      CorrectionsActions.resubmitRequest({ correctionsForm, originalForm })
    );
  }

  resubmitTdcRequest(tdcCorrectionsForm: CorrectionsTdcFormValueModel): void {
    this.store$.dispatch(CorrectionsActions.resubmitTdcRequest({ tdcCorrectionsForm }));
  }

  updateUnlockCorrectionRequestStatus(
    correctionRequestInstIds: number[]
  ): void {
    this.store$.dispatch(
      CorrectionsActions.updateUnlockCorrectionRequestStatus({
        correctionRequestInstIds,
      })
    );
  }

  updateQueueForCorrectionRequests(
    correctionRequestInstIds: number[],
    queueName: string
  ): void {
    this.store$.dispatch(
      CorrectionsActions.updateQueueForCorrectionRequests({
        correctionRequestInstIds,
        queueName,
      })
    );
  }
}
