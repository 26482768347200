import { createAction, props } from "@ngrx/store";
import { ErrorModel } from "@shared/models/shared/error.model";

export class RecallModel {
    proNbr: string;
    pickupDate: string;
};


export abstract class RecallActions {
    static recallPros = createAction(
        '[RecallActions] Recall pro',
        props<{ proNbrs: string[] }>()
    );
    static recallProsSuccess = createAction(
        '[RecallActions] Recall data Success',
        props<{ recall: RecallModel }>()
    );
    static recallProsFail = createAction(
        '[RecallActions] Recall data Fail',
        props<{ errors: ErrorModel, proNbr: string }>()
    );
}
