import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TransformCommonDataFieldsService {

  constructor() { }

  getNumberFromTextField(value: number | string | null | undefined): number | null {
    if (typeof value === 'number') return value;
    if (typeof value === 'string' && value) {
      const numberValue = Number(value.replace(/[, $]/g, ''));
      return isNaN(numberValue) ? null : numberValue;
    }
    return 0;
  }

  getValueFromTimeField(timeValue: string): string {
    if(!timeValue) return timeValue;
    const parts = timeValue.split(':');
    while (parts.length < 3) {
      parts.push('00');
    }
    return parts.slice(0, 3).join(':');
  }
  
}
