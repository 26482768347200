export enum ApiErrorCodesEnum {
  auditEscalationError = 'BCRN021-049E',
  noPaymentFound = 'ARAN010-002E',
  unratedPro = 'BCRP021-001W',
  balanceDueError = 'BCRN021-051E',
  tdcByDateError = 'BCRN021-053E',
  tdcOnDateError = 'BCRN021-054E',
  tdcFromDateError = 'BCRN021-055E',
  tdcToDateError = 'BCRN021-056E',
  isLateFeeError = 'BCRN021-052E',
}
