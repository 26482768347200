import { FormArray, FormGroup } from "@angular/forms";
import { CorrectionsFormEnum } from "@shared/enums/corrections/corrections-form/corrections-form.enum";
import { CorrectionsAccessorialsModel } from "./accessorials/corrections-accessorials.model";
import { CorrectionsAdvanceScacModel } from "./advance-scac/corrections-advance-scac.model";
import { CorrectionsAmcAmountModel } from "./amc-amount/corrections-amc-amount.model";
import { CorrectionsAuditorRemarksModel } from "./auditor-remarks/corrections-auditor-remarks.model";
import { CorrectionsBeyondScacModel } from "./beyond-scac/corrections-beyond-scac.model";
import { CorrectionsBillToModel } from "./billto/corrections-billto.model";
import { CorrectionsCancelChargesModel } from "./cancel-charges/corrections-cancel-charges.model";
import { CorrectionsCashCollectedFormValuesModel } from "./cash-collected/corrections-cash-collected.model";
import { CorrectionsCashPaidModel } from "./cash-paid/corrections-cash-paid.model";
import { CorrectionsChargeCodeModel } from "./charge-code/corrections-charge-code.model";
import { CorrectionsCodAmountModel } from "./cod-amount/corrections-cod-amount.model";
import { CorrectionCommoditiesModel } from "./commodities/corrections-commodities.model";
import { CorrectionsConsigneeModel } from "./consignee/corrections-consignee.model";
import { CorrectionsCustomInBondModel } from "./custom-in-bond/corrections-custom-in-bond.model";
import { CorrectionsDiscountAmountModel } from "./discount-amount/corrections-discount-amount.model";
import { CorrectionGuaranteedModel } from "./guaranteed/corrections-guaranteed.model";
import { CorrectionsMeasuresModel } from "./measures/corrections-measures.model";
import { CorrectionsMiscellaneousModel } from "./miscellaneous/corrections-miscellaneous.model";
import { CorrectionsReasonModel } from "./reason/corrections-reason.model";
import { CorrectionsRequestInfoModel } from "./request-info/corrections-request-info.model";
import { CorrectionsRevenuesModel } from "./revenues/corrections-revenues.model";
import { CorrectionsShipperModel } from "./shipper/corrections-shipper.model";
import { CorrectionsShippingRemarksFormValuesModel } from "./shipping-remarks/corrections-shipping-remarks.model";
import { CorrectionSicFormGroupModel } from "./sic/corrections-sic.model";
import { CorrectionsTariffFormValuesModel } from "./tariff/corrections-tariff.model";
import { CorrectionsTimeDeliveryWindowModel } from "./time-delivery-window/corrections-time-delivery-window.model";
import { CorrectionsWeightModel } from "./weight/corrections-weight.model";
import { QueryList } from "@angular/core";
import { CorrectionsIndicatorsModel } from "./indicators/corrections-indicators.model";

export class TooltipConfigModel {
  title: string;
  message: string;
}

export class CorrectionsFormModel {
  [CorrectionsFormEnum.accessorials]: FormArray;
  [CorrectionsFormEnum.advanceScac]: FormGroup;
  [CorrectionsFormEnum.amcAmount]: FormGroup;
  [CorrectionsFormEnum.beyondScac]: FormGroup;
  [CorrectionsFormEnum.billto]: FormGroup;
  [CorrectionsFormEnum.cashCollected]: FormGroup;
  [CorrectionsFormEnum.cashPaid]: FormGroup;
  [CorrectionsFormEnum.cancelCharges]: FormGroup;
  [CorrectionsFormEnum.chargeCode]: FormGroup;
  [CorrectionsFormEnum.codAmount]: FormGroup;
  [CorrectionsFormEnum.commodities]: FormArray;
  [CorrectionsFormEnum.consignee]: FormGroup;
  [CorrectionsFormEnum.customInBond]: FormGroup;
  [CorrectionsFormEnum.discountAmount]: FormGroup;
  [CorrectionsFormEnum.guaranteed]: FormGroup;
  [CorrectionsFormEnum.measures]: FormGroup;
  [CorrectionsFormEnum.miscellaneous]: FormGroup;
  [CorrectionsFormEnum.reason]: FormGroup;
  [CorrectionsFormEnum.invoicing]: FormGroup;
  [CorrectionsFormEnum.auditorRemarks]: FormGroup;
  [CorrectionsFormEnum.requestInfo]: FormGroup;
  [CorrectionsFormEnum.revenues]: FormGroup;
  [CorrectionsFormEnum.shipper]: FormGroup;
  [CorrectionsFormEnum.shippingRemarks]: FormGroup;
  [CorrectionsFormEnum.formIndicators]: FormGroup;
  [CorrectionsFormEnum.sic]: FormGroup;
  [CorrectionsFormEnum.tariff]: FormGroup;
  [CorrectionsFormEnum.weight]: FormGroup;
};

export class CorrectionsTdcFormModel {
  [CorrectionsFormEnum.timeDeliveryWindow]: FormGroup;
  [CorrectionsFormEnum.auditorRemarks]: FormGroup;
};

export class CorrectionsFormValueModel {
  [CorrectionsFormEnum.accessorials]: CorrectionsAccessorialsModel[];
  [CorrectionsFormEnum.advanceScac]: CorrectionsAdvanceScacModel;
  [CorrectionsFormEnum.amcAmount]: CorrectionsAmcAmountModel;
  [CorrectionsFormEnum.beyondScac]: CorrectionsBeyondScacModel;
  [CorrectionsFormEnum.billto]: CorrectionsBillToModel;
  [CorrectionsFormEnum.cashCollected]: CorrectionsCashCollectedFormValuesModel;
  [CorrectionsFormEnum.cashPaid]: CorrectionsCashPaidModel;
  [CorrectionsFormEnum.cancelCharges]: CorrectionsCancelChargesModel;
  [CorrectionsFormEnum.chargeCode]: CorrectionsChargeCodeModel;
  [CorrectionsFormEnum.codAmount]: CorrectionsCodAmountModel;
  [CorrectionsFormEnum.commodities]: CorrectionCommoditiesModel[];
  [CorrectionsFormEnum.consignee]: CorrectionsConsigneeModel;
  [CorrectionsFormEnum.customInBond]: CorrectionsCustomInBondModel;
  [CorrectionsFormEnum.discountAmount]: CorrectionsDiscountAmountModel;
  [CorrectionsFormEnum.guaranteed]: CorrectionGuaranteedModel;
  [CorrectionsFormEnum.measures]: CorrectionsMeasuresModel;
  [CorrectionsFormEnum.miscellaneous]: CorrectionsMiscellaneousModel;
  [CorrectionsFormEnum.reason]: CorrectionsReasonModel;
  [CorrectionsFormEnum.invoicing]: any;
  [CorrectionsFormEnum.auditorRemarks]: CorrectionsAuditorRemarksModel;
  [CorrectionsFormEnum.requestInfo]: CorrectionsRequestInfoModel;
  [CorrectionsFormEnum.revenues]: CorrectionsRevenuesModel;
  [CorrectionsFormEnum.shipper]: CorrectionsShipperModel;
  [CorrectionsFormEnum.shippingRemarks]: CorrectionsShippingRemarksFormValuesModel;
  [CorrectionsFormEnum.sic]: CorrectionSicFormGroupModel;
  [CorrectionsFormEnum.tariff]: CorrectionsTariffFormValuesModel;
  [CorrectionsFormEnum.weight]: CorrectionsWeightModel | undefined;
  [CorrectionsFormEnum.auditEscalationInd]: CorrectionsAuditEscalationModel | undefined;
  [CorrectionsFormEnum.formIndicators]: CorrectionsIndicatorsModel;
  [CorrectionsFormEnum.timeDeliveryWindow]?: CorrectionsTimeDeliveryWindowModel;
};

export class CorrectionsAuditEscalationModel {
  [CorrectionsFormEnum.auditEscalationInd]: boolean;
};

export class CorrectionsTdcFormValueModel {
  [CorrectionsFormEnum.requestInfo]: CorrectionsRequestInfoModel;
  [CorrectionsFormEnum.reason]: CorrectionsReasonModel;
  [CorrectionsFormEnum.formIndicators]: CorrectionsIndicatorsModel;
  [CorrectionsFormEnum.timeDeliveryWindow]: CorrectionsTimeDeliveryWindowModel;
  [CorrectionsFormEnum.auditorRemarks]: CorrectionsAuditorRemarksModel;
};

export class CorrectionsFormCreateRequestResponseModel {
  proNbr: string;
};

export interface ICorrectionsFormComponents {
  form: FormGroup | FormArray;
};

export interface ICorrectionsCommonFormComponents {
  commonFormComponent: QueryList<ICorrectionsFormComponents>
}
