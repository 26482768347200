import { Injectable } from '@angular/core';
import { CustomerLocationRespModel } from '@shared/models/corrections/corrections-detail.model';
import { CorrectionsCustomerModel } from '@shared/models/corrections/corrections-form/customer/customer-form.model';
import { CustomerMatch, GetCustomerLocationQuery, GetCustomerLocationResp, MatchCustomerByMadCodePath, MatchCustomerByMadCodeQuery, MatchCustomerByMadCodeResp } from '@xpo-ltl-2.0/sdk-customer';
import { CustomerFunctionCd, CustomerMatchResultTypeCd } from '@xpo-ltl/sdk-common';

@Injectable()
export class CustomerMatchMapperService {

  constructor() { }

  getPathParams(madCd: string): MatchCustomerByMadCodePath {
    const pathParams = new MatchCustomerByMadCodePath();
    pathParams.madCode = madCd;

    return pathParams;
  }

  getQueryParams(customerTypes: CustomerFunctionCd[]): MatchCustomerByMadCodeQuery {
    const queryParams = new MatchCustomerByMadCodeQuery();
    queryParams.customerFunctionCd = customerTypes;

    return queryParams;
  }

  getMappedData(apiResponse: MatchCustomerByMadCodeResp): CorrectionsCustomerModel {
    
    const matchList: CustomerMatch[] = apiResponse.customerMatch || [];
    const customerRecords = matchList.filter(m => m.matchResultTypeCd === CustomerMatchResultTypeCd.CUSTOMER);

    if (!customerRecords.length) {
      throw new Error('No main (Customer) record found');
    }

    const main = customerRecords[0];
    return this.mapCustomerData(main);
  }

  mapCustomerData(customer: CustomerMatch): CorrectionsCustomerModel {
    return {
      madCode: customer.madCd,
      customerNumber: customer.customerNbr,
      nameOne: customer.name1,
      nameTwo: customer.name2,
      address: customer.addressLine1,
      city: customer.cityName,
      state: customer.stateCd,
      zipCode: customer.postalCd,
      country: customer.countryCd,
      statusCd: customer.statusCd,
      zipCodeComplementation: customer.usZip4 ?? ''
    };
  }
}
