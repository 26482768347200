import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { RestoreArchivedShipmentResp, RestoreArchivedShipmentRqst, ShipmentOdsApiService } from '@xpo-ltl/sdk-shipmentods';
import { DataOptions } from '@xpo-ltl/data-api';


@Injectable({
  providedIn: 'root'
})
export class RecallApiService {

  constructor(private shipmentOdsApiService: ShipmentOdsApiService) { }

  recallPro(request: RestoreArchivedShipmentRqst): Observable<RestoreArchivedShipmentResp> {
    const dataOptions: DataOptions = { toastOnError: false };
    return this.shipmentOdsApiService.restoreArchivedShipments(request, dataOptions).pipe(
      catchError((error: any) => {
        console.error('RecallApiService', 'recallPro', error);
        return throwError(error);
      })
    );
  }
}
