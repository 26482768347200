<div class="container">
    <xpo-shell>
        <xpo-header-app-name>Edge</xpo-header-app-name>
        <xpo-header-sub-app-name>{{ title }}</xpo-header-sub-app-name>
        <xpo-header-navigation>
            <button [routerLink]="getCorrectionsLink()" routerLinkActive="xpo-selected">Corrections</button>
            <button [routerLink]="getRecallLink()" routerLinkActive="xpo-selected">Shipment Recall</button>
        </xpo-header-navigation>
        <xpo-header-actions>
          <xpo-auth-app-switcher [applications]="switcherApps$ | async"></xpo-auth-app-switcher>
          <xpo-auth-user-profile>
            <xpo-auth-user-profile-links>
              <a [href]="accountUrls.loggedUserUrl" target="_blank">My Account</a>
            </xpo-auth-user-profile-links>
          </xpo-auth-user-profile>                
        </xpo-header-actions>
        <div class="content">
            <router-outlet></router-outlet>
        </div>
    </xpo-shell>
    <app-main-footer></app-main-footer>
    <xpo-app-notification></xpo-app-notification>
</div>
