import { Injectable } from '@angular/core';
import { HandleErrorApiService } from '@shared/utils/apis/error/handle-error-api.service';
import { CustomerApiService, GetInvoiceInstructionHistoryPath, GetInvoicingInstructionsResp } from '@xpo-ltl-2.0/sdk-customer';
import { GetCreditStatusPath, GetCreditStatusResp, GetCustomerLocationQuery, GetCustomerLocationResp, MatchCustomerByMadCodePath, MatchCustomerByMadCodeQuery, MatchCustomerByMadCodeResp } from '@xpo-ltl-2.0/sdk-customer/lib/api-customer';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class CustomerDataApiService {

  constructor(
    private customerApiService: CustomerApiService,
    private handleErrorApiService: HandleErrorApiService
  ) { }

  getInvoicingInstructions(pathParams: GetInvoiceInstructionHistoryPath): Observable<GetInvoicingInstructionsResp> {
    return this.customerApiService.getInvoicingInstructions(pathParams).pipe(
      catchError(response => {
        console.error(response);
        return throwError(
          this.handleErrorApiService.handleError<GetInvoicingInstructionsResp>(
            response
          )
        );
      })
    ) as any as Observable<GetInvoicingInstructionsResp>;
  }

  getCreditStatus(pathParams: GetCreditStatusPath): Observable<GetCreditStatusResp> {
    return this.customerApiService.getCreditStatus(pathParams).pipe(
      catchError(response => {
        console.error(response);
        return throwError(
          this.handleErrorApiService.handleError<GetCreditStatusResp>(
            response
          )
        );
      })
    ) as any as Observable<GetCreditStatusResp>;
  }

  getDestinationSicCode(pathParams: GetCustomerLocationQuery): Observable<GetCustomerLocationResp> {
    return this.customerApiService.getCustomerLocation(pathParams).pipe(
      catchError(response => {
        console.error(response);
        return throwError(
          this.handleErrorApiService.handleError<GetCreditStatusResp>(
            response
          )
        );
      })
    ) as any as Observable<GetCustomerLocationResp>;
  }

  matchCustomerByMadCode(pathParams: MatchCustomerByMadCodePath, queryParams: MatchCustomerByMadCodeQuery): Observable<MatchCustomerByMadCodeResp> {
    return this.customerApiService.matchCustomerByMadCode(pathParams, queryParams).pipe(
      catchError(response => {
        console.error(response);
        return throwError(
          this.handleErrorApiService.handleError<MatchCustomerByMadCodeResp>(
            response
          )
        );
      })
    ) as any as Observable<MatchCustomerByMadCodeResp>;
  }
}
