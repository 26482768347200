export enum CorrectionsDiscountAmountFormEnum {
  delete = 'delete',
  percentage = 'percentage',
  description = 'description',
  sequenceNbr = 'sequenceNbr',
  amount = 'amount',
};

export enum CorrectionsDiscountAmountFormLabelEnum {
  amount = 'Amount',
  delete = 'Delete',
  description = 'Description',
  percentage = 'Percentage',
  recalc = 'Re-Calc'
};

export enum CorrectionsDiscountAmountFormTitlesEnum {
  title = 'DISCOUNT AMOUNT'
};

export enum CorrectionsDiscountAmountFormIdsEnum {
  amount = 'discountSectionAmount',
  delete = 'discountSectionDelete',
  description = 'discountSectionDescription',
  percentage = 'discountSectionPercentage',
  recalc = 'reCalcBtn',
};

export enum CorrectionsDiscountAmountRulesEnum {
  maxPercentage = 99.99,
  minPercentage = 0,
  maxAmountValue = 999999.99,
  minAmountValue = 0,
}
