import { ValidationRegexPatterns } from "@xpo-ltl/common-services";
import { RegexRules } from "./regex.utils";

export const validateApiTechnicalCodeError = (codeError: string): boolean => {
  return new RegExp(RegexRules.apiTechnicalCodeError).test(codeError);
};

export function boldingNumbers(input: string): string {
  const regex = RegexRules.onlyNumbersAndMoneySign;
  return input.replace(regex, '<b>$&</b>');
}

export function isPstValidDate(dateToCompare: string): boolean {
  const regex = RegexRules.pstValidationDate;
  return regex.test(dateToCompare);
}

//BUSSINESS VALIDATORS

export const validateIfOnlyNumbers = (text: string): boolean => {
  return new RegExp(ValidationRegexPatterns.numberCheck).test(text);
};

export const validateProNumberLength = (text: string): boolean => {
  return RegexRules.proNumberLength.test(text);
};

export const validateProNumberNineCharacters = (text: string): boolean => {
  return new RegExp(ValidationRegexPatterns.nineDigitPro).test(text);
};

export const validateProNumberTenCharacters = (text: string): boolean => {
  return new RegExp(ValidationRegexPatterns.tenDigitPro).test(text);
};

export const validateProNumberElevenCharacters = (text: string): boolean => {
  return new RegExp(ValidationRegexPatterns.elevenDigitPro).test(text);
};

export const trimSecondsAndPeriod = (value: string): string => {
  if (!value || value.split(":").length !== 3) return value;
  return value.replace(RegexRules.trimSecondsAndPeriod, '');
}

export const formatPeriodTime = (value: string) => {
  if (!value || value.split(':').length !== 3) return value;
  const getHour = (timeValue: string) =>  parseInt(timeValue.split(':')[0], 10);

  return value.replace(RegexRules.trimSeconds, (match: string, offset: number, timeValue: string) => {
    if(timeValue == null || timeValue === '') return '';
    const hour = getHour(timeValue);
    return hour < 12 ? ':AM' : ':PM';
  }).replace(RegexRules.trimHour, (match: string, offset: number, timeValue: string) => {
    if(timeValue == null || timeValue === '') return '';
    const hour = getHour(timeValue);
    let newHour = hour > 12 ? hour - 12 : hour;
    return `${newHour}`.padStart(2, '0');
  });
}
