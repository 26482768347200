import { createFeatureSelector, createReducer, on } from "@ngrx/store";
import { CorrectionsFormViewEnum, CorrectionsRequestFormTypeEnum } from "@shared/enums/corrections/corrections-form/corrections-form.enum";
import { CorrectionsBillChargesModel } from "@shared/models/corrections/bill-payment/corrections-bill-charges.model";
import { CorrectionsDetailModel } from "@shared/models/corrections/corrections-detail.model";
import { CorrectionsFormValueModel, CorrectionsTdcFormModel } from "@shared/models/corrections/corrections-form/corrections-form.model";
import { CorrectionsListMetadataModel } from "@shared/models/corrections/corrections-list-metadata.model";
import { CorrectionsPreviewRatesModel } from "@shared/models/corrections/preview-rates/corrections-preview-rates.model";
import { CorrectionsActions } from "../actions/corrections.actions";

const correctionsFeatureKey = 'correctionsKey';
const correctionsFeatureSelector = createFeatureSelector<CorrectionsStateModel>(correctionsFeatureKey);

export interface CorrectionsStateModel {
  proNbr: string;
  isCurrentRequestFormLocked: boolean;
  isUnratedPro: boolean;
  correctedFormFields: CorrectionsFormValueModel | undefined;
  currentRequestForm: CorrectionsFormValueModel | undefined;
  currentRequestFormTdc: CorrectionsTdcFormModel | undefined;
  originalRequestForm: CorrectionsFormValueModel | undefined;
  correctionsBillCharges: CorrectionsBillChargesModel | undefined;
  correctionsDetail: CorrectionsDetailModel | undefined;
  correctionsCorrectedDetail: CorrectionsDetailModel | undefined;
  correctionsHistoryDetail: CorrectionsDetailModel | undefined;
  correctionsEditMetadata: CorrectionsListMetadataModel | undefined;
  correctionsAuditMetadata: CorrectionsListMetadataModel | undefined;
  correctionsReassignMetadata: CorrectionsListMetadataModel | undefined;
  autoRatePreview: CorrectionsPreviewRatesModel | undefined;
  manualRatePreview: CorrectionsPreviewRatesModel | undefined;
  loadingGetBillCharges: boolean;
  loadingSubmit: boolean;
  isLateFeeEnable: boolean;
  loadingCorrectedRevenue: boolean;
  requestFormType: CorrectionsRequestFormTypeEnum | undefined;
  requestFormView: CorrectionsFormViewEnum | undefined;
  tdcOptionRequestFormDisabled: boolean;
  updateCorrectionRequestLoading: boolean;
}

const initialState: CorrectionsStateModel = {
  proNbr: '',
  isUnratedPro: false,
  isCurrentRequestFormLocked: false,
  correctedFormFields: undefined,
  currentRequestForm: undefined,
  currentRequestFormTdc: undefined,
  originalRequestForm: undefined,
  correctionsBillCharges: undefined,
  correctionsDetail: undefined,
  correctionsCorrectedDetail: undefined,
  correctionsHistoryDetail: undefined,
  correctionsEditMetadata: undefined,
  correctionsAuditMetadata: undefined,
  correctionsReassignMetadata: undefined,
  manualRatePreview: undefined,
  autoRatePreview: undefined,
  loadingGetBillCharges: false,
  loadingSubmit: false,
  isLateFeeEnable: false,
  loadingCorrectedRevenue: false,
  requestFormType: undefined,
  requestFormView: undefined,
  tdcOptionRequestFormDisabled: false,
  updateCorrectionRequestLoading: false,
};

const reducer = createReducer(
  initialState,
  on(CorrectionsActions.setProNbr, (state, { proNbr }): CorrectionsStateModel => {
    return {
      ...state,
      proNbr,
    };
  }),
  on(CorrectionsActions.setIsUnrated, (state, { isUnratedPro }): CorrectionsStateModel => {
    return {
      ...state,
      isUnratedPro,
    };
  }),
  on(CorrectionsActions.setIsLateFee, (state, { isLateFeeEnable }): CorrectionsStateModel => {
    return {
      ...state,
      isLateFeeEnable,
    };
  }),
  on(CorrectionsActions.setRequestFormType, (state, { requestFormType }): CorrectionsStateModel => {
    return {
      ...state,
      requestFormType,
    };
  }),
  on(CorrectionsActions.setRequestFormView, (state, { requestFormView }): CorrectionsStateModel => {
    return {
      ...state,
      requestFormView,
    };
  }),
  on(CorrectionsActions.setCurrentRequestFormLockedInd, (state, { lockedInd }): CorrectionsStateModel => {
    return {
      ...state,
      isCurrentRequestFormLocked: lockedInd,
    };
  }),
  on(CorrectionsActions.setDisableTdcOptionRequestForm, (state, { optionDisabled }): CorrectionsStateModel => {
    return {
      ...state,
      tdcOptionRequestFormDisabled: optionDisabled,
    };
  }),
  on(CorrectionsActions.clearProNbr, (state): CorrectionsStateModel => {
    return {
      ...state,
      proNbr: '',
    };
  }),
  on(CorrectionsActions.getBillCharges, (state): CorrectionsStateModel => {
    return {
      ...state,
      correctionsBillCharges: undefined,
      loadingGetBillCharges: true,
    };
  }),
  on(CorrectionsActions.getBillChargesSuccess, (state, { billCharges }): CorrectionsStateModel => {
    return {
      ...state,
      correctionsBillCharges: billCharges,
      loadingGetBillCharges: false,
    };
  }),
  on(CorrectionsActions.getBillChargesFail, (state): CorrectionsStateModel => {
    return {
      ...state,
      loadingGetBillCharges: false,
    };
  }),
  on(CorrectionsActions.getDetailsSuccess, (state, { correctionsDetail }): CorrectionsStateModel => {
    return {
      ...state,
      correctionsDetail: correctionsDetail,
      correctionsCorrectedDetail: correctionsDetail,
    };
  }),
  on(CorrectionsActions.getManualRatePreview, (state): CorrectionsStateModel => {
    return {
      ...state,
      manualRatePreview: undefined
    };
  }),
  on(CorrectionsActions.getManualRatePreviewSuccess, (state, { manualRatesPreview }): CorrectionsStateModel => {
    return {
      ...state,
      manualRatePreview: manualRatesPreview
    };
  }),
  on(CorrectionsActions.getAutoRatePreview, (state): CorrectionsStateModel => {
    return {
      ...state,
      autoRatePreview: undefined
    };
  }),
  on(CorrectionsActions.getAutoRatePreviewSuccess, (state, { autoRatesPreview }): CorrectionsStateModel => {
    return {
      ...state,
      autoRatePreview: autoRatesPreview
    };
  }),
  on(CorrectionsActions.getListCorrectionsEditMetaData, (state): CorrectionsStateModel => {
    return {
      ...state,
      correctionsEditMetadata: undefined,
    };
  }),
  on(CorrectionsActions.getListCorrectionsAuditMetaData, (state): CorrectionsStateModel => {
    return {
      ...state,
      correctionsAuditMetadata: undefined,
    };
  }),
  on(CorrectionsActions.getListCorrectionsReassignMetaData, (state): CorrectionsStateModel => {
    return {
      ...state,
      correctionsReassignMetadata: undefined,
    };
  }),
  on(
    CorrectionsActions.getListCorrectionsEditMetaDataSuccess,
    (state, { correctionsEditMetadata }): CorrectionsStateModel => {
      return {
        ...state,
        correctionsEditMetadata,
      };
    }
  ),
  on(
    CorrectionsActions.getListCorrectionsAuditMetaDataSuccess,
    (state, { correctionsAuditMetadata }): CorrectionsStateModel => {
      return {
        ...state,
        correctionsAuditMetadata,
      };
    }
  ),
  on(
    CorrectionsActions.getListCorrectionsReassignMetaDataSuccess,
    (state, { correctionsReassignMetadata }): CorrectionsStateModel => {
      return {
        ...state,
        correctionsReassignMetadata,
      };
    }
  ),
  on(
    CorrectionsActions.setOriginalRequestForm,
    (state, { originalRequestForm }): CorrectionsStateModel => {
      return {
        ...state,
        originalRequestForm,
      };
    }
  ),
  on(
    CorrectionsActions.setCurrentRequestForm,
    (state, { currentRequestForm }): CorrectionsStateModel => {
      return {
        ...state,
        currentRequestForm,
      };
    }
  ),
  on(
    CorrectionsActions.setCurrentRequestFormTdc,
    (state, { currentRequestFormTdc }): CorrectionsStateModel => {
      return {
        ...state,
        currentRequestFormTdc,
      };
    }
  ),
  on(
    CorrectionsActions.getDetails,
    (state): CorrectionsStateModel => {
      return {
        ...state,
        isUnratedPro: false
      };
    }
  ),
  on(
    CorrectionsActions.getPendingCorrectionDetails,
    (state): CorrectionsStateModel => {
      return {
        ...state,
        correctionsCorrectedDetail: undefined,
        correctionsDetail: undefined,
        isUnratedPro: false
      };
    }
  ),
  on(
    CorrectionsActions.getPendingCorrectionDetailsSuccess,
    (state, { originalDetail, correctedDetail, correctedFormFields }): CorrectionsStateModel => {
      return {
        ...state,
        correctionsCorrectedDetail: correctedDetail,
        correctionsDetail: originalDetail,
        correctedFormFields
      };
    }
  ),
  on(
    CorrectionsActions.submitAuditorRequest,
    (state): CorrectionsStateModel => {
      return {
        ...state,
        updateCorrectionRequestLoading: true,
        loadingSubmit: true,
      };
    }
  ),
  on(CorrectionsActions.submitAuditorRequestSuccess, CorrectionsActions.submitAuditorRequestFail, (state): CorrectionsStateModel => {
      return {
        ...state,
        updateCorrectionRequestLoading: false,
        loadingSubmit: false,
      };
    }
  ),
  on(
    CorrectionsActions.submitRequest,
    (state): CorrectionsStateModel => {
      return {
        ...state,
        loadingSubmit: true,
      };
    }
  ),
  on(CorrectionsActions.submitRequestSuccess, CorrectionsActions.submitRequestFail, (state): CorrectionsStateModel => {
      return {
        ...state,
        loadingSubmit: false,
      };
    }
  ),
  on(
    CorrectionsActions.resubmitRequest,
    (state): CorrectionsStateModel => {
      return {
        ...state,
        loadingSubmit: true,
      };
    }
  ),
  on(CorrectionsActions.resubmitRequestSuccess, CorrectionsActions.resubmitRequestFail, (state): CorrectionsStateModel => {
      return {
        ...state,
        loadingSubmit: false,
      };
    }
  ),
  on(
    CorrectionsActions.submitTdcAuditorRequest,
    (state): CorrectionsStateModel => {
      return {
        ...state,
        loadingSubmit: true,
      };
    }
  ),
  on(CorrectionsActions.submitTdcAuditorRequestSuccess, CorrectionsActions.submitTdcAuditorRequestFail, (state): CorrectionsStateModel => {
      return {
        ...state,
        loadingSubmit: false,
      };
    }
  ),
  on(
    CorrectionsActions.submitTdcRequest,
    (state): CorrectionsStateModel => {
      return {
        ...state,
        loadingSubmit: true,
      };
    }
  ),
  on(CorrectionsActions.submitTdcRequestSuccess, CorrectionsActions.submitTdcRequestFail, (state): CorrectionsStateModel => {
      return {
        ...state,
        loadingSubmit: false,
      };
    }
  ),
  on(
    CorrectionsActions.resubmitTdcRequest,
    (state): CorrectionsStateModel => {
      return {
        ...state,
        loadingSubmit: true,
      };
    }
  ),
  on(CorrectionsActions.resubmitTdcRequestSuccess, CorrectionsActions.resubmitTdcRequestFail, (state): CorrectionsStateModel => {
      return {
        ...state,
        loadingSubmit: false,
      };
    }
  ),
  on(
    CorrectionsActions.getManualRatePreview,
    (state): CorrectionsStateModel => {
      return {
        ...state,
        loadingSubmit: true,
      };
    }
  ),
  on(CorrectionsActions.getManualRatePreviewSuccess, CorrectionsActions.getManualRatePreviewFail, (state): CorrectionsStateModel => {
      return {
        ...state,
        loadingSubmit: false,
      };
    }
  ),
  on(
    CorrectionsActions.getAutoRatePreview,
    (state): CorrectionsStateModel => {
      return {
        ...state,
        loadingSubmit: true,
      };
    }
  ),
  on(CorrectionsActions.getAutoRatePreviewSuccess, CorrectionsActions.getAutoRatePreviewFail, (state): CorrectionsStateModel => {
      return {
        ...state,
        loadingSubmit: false,
      };
    }
  ),
  on(
    CorrectionsActions.getCorrectedRevenues,
    (state): CorrectionsStateModel => {
      return {
        ...state,
        loadingCorrectedRevenue: true,
      };
    }
  ),
  on(CorrectionsActions.getCorrectedRevenuesSuccess, CorrectionsActions.getCorrectedRevenuesFail, (state): CorrectionsStateModel => {
      return {
        ...state,
        loadingCorrectedRevenue: false,
      };
    }
  ),
);

export const CorrectionsReducer = {
  reducer,
  correctionsDetailFeatureKey: correctionsFeatureKey,
  correctionsDetailFeatureSelector: correctionsFeatureSelector,
  initialState,
};
