import { DateFormat, DateTimezone } from '@shared/enums/common-data/date-format.enum';
import * as moment from 'moment';
import 'moment-timezone';

export class DateUtils {

  constructor() { }

  static momentToString(date: string | moment.MomentInput, format = DateFormat.defaultApiDateFormat): string {
    if (!date) return '';
    return typeof date === 'string' ? date : moment(date).format(format);
  };

  static momentToStringTimezone(date: string | moment.MomentInput, format = DateFormat.defaultApiDateFormat, timezone = DateTimezone.local): string {
    if (!date) return '';
    return timezone ? moment(date).tz(timezone).format(format) : moment(date).format(format);
  };

  static getTimeFromMoment(date: number | moment.MomentInput): number | undefined {
    if (!date) return;
    return typeof date === 'number' ? date : moment(date).valueOf();
  };

  static getDateFromString(date: string, format = DateFormat.defaultApiDateFormat): moment.Moment {
    return moment(date, format);
  }

  static getCurrentDateString(format = DateFormat.defaultApiDateFormat): string {
    return this.momentToString(moment(), format);
  }

  static getCurrentDateTimeStamp(): number {
    return Date.now();
  }

  static getDateFromTimeStamp(timestamp: number, format = DateFormat.timestamp, timezone = DateTimezone.local): string {
    return timezone ? moment(timestamp).tz(timezone).format(format) : moment(timestamp).format(format);
  }

  static getDateWithFormat(date: string, dateFormat = DateFormat.defaultApiDateFormat, format = DateFormat.defaultApiDateFormat): string {
    return moment(date, dateFormat).format(format).toString();
  }

  static dateComparator(date1: string, date2: string): number {
    if (!date1 && !date2) {
      return 0;
    }
    if (!date1) {
      return -1;
    }
    if (!date2) {
      return 1;
    }
    const date1Moment = moment(date1, DateFormat.timestamp);
    const date2Moment = moment(date2, DateFormat.timestamp);

    return date1Moment > date2Moment ? 1 : -1;
  }

  static convertTo24HourFormat(time: string): string {
    if (!time) return '';
  
    return moment(time, 'hh:mm:A').format('HH:mm');
  }
}
